import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";
import { useLanguage } from "../../i18n/LanguageContext";
import { SanityLandingPageBlockColorHero } from "../../model/landing-page";
import { useIsRtl } from "../../utils/LayoutContext";
import BlockContent from "../BlockContent/BlockContent";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import { Wrapper } from "../Grid";
import { internalLinkUrl } from "../InternalLink/InternalLink";
import YoutubeVideo from "../YoutubeVideoBlock/YoutubeVideoBlock";
import styles from "./BlockColorHero.module.scss";

const BlockColorHero = ({
  value
}: {
  value: SanityLandingPageBlockColorHero;
}) => {
  const { callToAction, color, content, video } = value;
  const externalLink = callToAction.externalLink?.href;
  const internalLink = callToAction.internalLink?.reference;
  const query = callToAction.internalLink?.query;
  const fragment = callToAction.internalLink?.fragment;
  const language = useLanguage();
  const to = internalLink
    ? internalLinkUrl(internalLink, query, fragment, language)
    : externalLink || "";
  const isRtl = useIsRtl();
  return (
    <>
      <Wrapper className={classNames(styles.root, styles[color])}>
        <div className={styles.hero} dir={isRtl ? "rtl" : "ltr"}>
          <BlockContent
            className={styles.content}
            bodyVariant="default"
            content={content}
            after={[
              <CallToActionButton
                key="cta"
                node={{
                  primary: true,
                  title: callToAction.title,
                  externalLink,
                  internalLink,
                  query,
                  fragment
                }}
              />
            ]}
          />
          <div className={styles.linkWrapper}>
            <Link to={to} className={styles.link}>
              {video.youtubeId && <YoutubeVideo node={video} />}
            </Link>
          </div>
        </div>
      </Wrapper>
      {video.youtubeId && (
        <YoutubeVideo className={styles.videoMobile} node={video} />
      )}
    </>
  );
};

export default BlockColorHero;
