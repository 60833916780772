import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import groupBy from "lodash.groupby";
import orderBy from "lodash.orderby";
import React from "react";
import ArticleCard from "../components/ArticleCard/ArticleCard";
import BlockColorHero from "../components/BlockColorHero/BlockColorHero";
import DybProjectCard from "../components/DybProjectCard/DybProjectCard";
import GenericCard from "../components/GenericCard/GenericCard";
import { Wrapper } from "../components/Grid";
import { internalLinkUrl } from "../components/InternalLink/InternalLink";
import TeachRow from "../components/TeachRow/TeachRow";
import { useString } from "../i18n/GetText";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityPage } from "../model/common";
import {
  SanityDybProject,
  SanityDybProjectsLandingPage
} from "../model/impact";
import { GlobalMenus } from "../model/menu";
import { urlForDocument } from "../urls";
import { SiteArea } from "../utils/analytics";
import { getSanitizedLocationId } from "../utils/country";
import { createRNG, randomInt, shuffleArray } from "../utils/ssr-random";
import styles from "./DybProjectsLandingPage.module.scss";

export const pageQuery = graphql`
  query DybProjectsLandingPage(
    $_id: String!
    $language: String
    $navLanguage: String
  ) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    dybProjectListingPage: sanityDybProjectListingPage(
      language: { eq: $language }
    ) {
      _id
      _type
      title
    }
    page: sanityDybProjectsLandingPage(_id: { eq: $_id }) {
      _id
      _type
      title
      _rawContent(resolveReferences: { maxDepth: 2 })
      video {
        alt
        youtubeId
      }
      callToAction {
        title
        internalLink {
          reference {
            ...InternalLinkTarget
          }
          query
          fragment
        }
        externalLink {
          href
        }
      }
      highlightedProjectsTitle
      highlightedProjectsSubtitle
      dybChallengeResources {
        title
        subtitle
        viewMoreText
        internalLink {
          reference {
            ...InternalLinkTarget
          }
          query
          fragment
        }
        _rawContent(resolveReferences: { maxDepth: 7 })
        externalLink
        type
        items {
          ... on SanityCardInternalLink {
            reference {
              ...InternalLinkTarget
            }
          }
          ... on SanityGenericCard {
            title
            description
            desktopImage {
              ...SanityImage
            }
            mobileImage {
              ...SanityImage
            }
            titleOverImage
            internalLink {
              reference {
                ...InternalLinkTarget
              }
              fragment
              query
            }
            externalLink
          }
        }
      }

      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
    projects: allSanityDybProject(
      filter: { language: { eq: $language } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...DybProjectCard
      }
    }
  }
`;

interface ProjectRow {
  projects: SanityDybProject[];
  location: string;
  viewMoreURL: string;
}

const DybProjectsLandingPage = ({
  pageContext,
  data: { menus, page, projects, dybProjectListingPage },
  location
}: DybProjectsLandingPageProps) => {
  const {
    title,
    _rawContent,
    callToAction,
    video,
    highlightedProjectsTitle,
    highlightedProjectsSubtitle,
    dybChallengeResources
  } = page;

  const projectsByCountry = groupBy(projects.nodes, p => p.location.name);
  const rng = createRNG();
  const projectRows: ProjectRow[] = shuffleArray(
    Object.entries(projectsByCountry).map(([location, projects]) => ({
      location,
      projects,
      viewMoreURL: `${urlForDocument(
        dybProjectListingPage
      )}?filters=${getSanitizedLocationId(projects[0].location._id)}`
    })),
    rng
  );

  // Reshuffle for highlighted projects to prevent country order
  // determining highlighted project order.
  // shuffleArray(projects.nodes, getWeekOfYear(new Date(Date.now())));

  // Take one random project from 6 different countries
  const projectRowsForHighlights = shuffleArray(projectRows, rng).slice(0, 6);
  const highlightedDybProjects = projectRowsForHighlights.map(
    row => row.projects[randomInt(rng, row.projects.length)]
  );
  const viewMoreText = useString("search-projects", "Search projects");
  return (
    <PageLayout
      siteArea={SiteArea.IMPACT}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <BlockColorHero
        value={{
          _key: "hero",
          _type: "landingPageBlockColorHero",
          color: "deepPink",
          content: _rawContent,
          video,
          callToAction
        }}
      />
      <Wrapper className={styles.container}>
        {highlightedDybProjects.length && (
          <TeachRow
            title={highlightedProjectsTitle}
            subtitle={highlightedProjectsSubtitle}
            viewMoreText={viewMoreText}
            viewMoreURL={urlForDocument(dybProjectListingPage)}
            cards={highlightedDybProjects.map(project => (
              <DybProjectCard key={project._id} project={project} />
            ))}
          />
        )}
        {projectRows.map(projectRow => {
          const sortedProjects = orderBy(
            projectRow.projects,
            project => project.date,
            ["desc"]
          );
          const cards = sortedProjects.map(project => (
            <DybProjectCard key={project._id} project={project} />
          ));
          return (
            <TeachRow
              key={projectRow.location}
              title={projectRow.location}
              viewMoreText={viewMoreText}
              viewMoreURL={projectRow.viewMoreURL}
              cards={cards}
            />
          );
        })}
        {dybChallengeResources.items?.length && (
          <TeachRow
            title={dybChallengeResources.title}
            subtitle={dybChallengeResources.subtitle}
            viewMoreText={dybChallengeResources.viewMoreText}
            viewMoreURL={
              dybChallengeResources.internalLink
                ? internalLinkUrl(
                    dybChallengeResources.internalLink.reference,
                    dybChallengeResources.internalLink.query,
                    dybChallengeResources.internalLink.fragment,
                    undefined
                  )
                : dybChallengeResources.externalLink!
            }
            cards={dybChallengeResources.items.map(item =>
              item._type === "cardInternalLink" ? (
                <ArticleCard
                  key={item.reference._id}
                  article={item.reference}
                  readMore={false}
                  widths={400}
                  textMaxLines={3}
                />
              ) : (
                <GenericCard
                  key={item.title}
                  layout="tile"
                  card={item}
                  widths={400}
                  readMore={false}
                  textMaxLines={3}
                />
              )
            )}
            content={dybChallengeResources._rawContent}
          />
        )}
      </Wrapper>
    </PageLayout>
  );
};

export default DybProjectsLandingPage;

interface DybProjectsLandingPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityDybProjectsLandingPage;
    dybProjectListingPage: SanityPage;
    projects: { nodes: SanityDybProject[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
