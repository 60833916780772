/**
 * For SSR this must be created anew for each call to render.
 * This uses a fixed seed set at build time and a simple LCG.
 */
export const createRNG = (): (() => number) => {
  const initialSeed = parseInt(process.env.GATSBY_RANDOM_SEED ?? "-1", 10);
  const a: number = 1664525;
  const c: number = 1013904223;
  const m: number = Math.pow(2, 32);

  let seed = initialSeed;

  return () => {
    seed = (a * seed + c) % m;
    return seed / m;
  };
};

// Random integer in range [0, max)
export const randomInt = (rng: () => number, max: number): number =>
  Math.floor(rng() * max);

// Iterative Fisher-Yates shuffle implementation
export const shuffleArray = <T>(arr: readonly T[], rng: () => number): T[] => {
  const result = [...arr];
  for (let i = result.length - 1; i > 0; i--) {
    const j = randomInt(rng, i + 1);
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
};
