import classNames from "classnames";
import React from "react";
import { SanityDybProject } from "../../model/impact";
import { urlForDocument } from "../../urls";
import CardContent from "../CardContent/CardContent";
import { BreakpointValues, ImageRatios } from "../Image/Image";
import ImageCard from "../ImageCard/ImageCard";
import styles from "./DybProjectCard.module.scss";

const DybProjectCard = ({
  className,
  project,
  widths = [232, 400, 400]
}: MakeCardProps) => {
  const url = urlForDocument(project);
  const { title, subtitle, image, location } = project;
  return (
    <ImageCard
      className={classNames(styles.root, className)}
      widths={widths}
      aspectRatio={ImageRatios.Landscape_4_3}
      image={image}
      href={url}
    >
      <CardContent
        title={title}
        text={subtitle}
        href={url}
        tags={location.name}
      />
    </ImageCard>
  );
};

interface MakeCardProps {
  className?: string;
  project: SanityDybProject;
  widths?: BreakpointValues;
}

export default DybProjectCard;
